import React from 'react'
import PropTypes from 'prop-types'
import Layout from '@organisms/layout'
import Heading from '@particles/heading'
import Text from '@particles/text'
import Link from '@atoms/link'
import SEO from '@atoms/seo'

const LegalPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="Legal" location={location} />
    <Heading h1 margin="T2 B2.5">
      Legal
    </Heading>
    <Text size={1.25}>
      We believe deeply in trusted relationships and we earn this by ensuring we
      respect your rights and safeguard the information you share with us.
      Always.
    </Text>
    <Text size={1.25} margin="T2">
      This section is designed to ensure that our users understand their rights
      and obligations related to using the MetricHQ platform, including our
      Privacy Policy and Terms of Service. We are committed to providing a safe
      and secure experience for our community. If you have any questions or
      concerns, please contact us. Thank you for using MetricHQ!
    </Text>
    <Text size={1.25} margin="T2">
      Legal links:
      <br />
      <ul>
        <li>
          <Link href="https://www.klipfolio.com/legal">Legal</Link>
        </li>
        <li>
          <Link href="https://www.klipfolio.com/legal/security">Security</Link>
        </li>
        <li>
          <Link href="https://www.klipfolio.com/legal/accessibility">
            Accessibility
          </Link>
        </li>
      </ul>
    </Text>
  </Layout>
)

LegalPage.propTypes = {
  location: PropTypes.any
}

export default LegalPage
